// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"

if (!window.bootstrap) {
  (async () => {
    await import('bootstrap').then((bootstrap) => window.bootstrap = bootstrap)
  })()
}

import '@vendor/color_admin/bundle'

Turbo.session.drive = false

document.addEventListener('DOMContentLoaded', () => {
  Array.from(document.querySelectorAll('[data-photo]')).forEach((el) => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          observer.disconnect()
          const image = new Image()
          image.onload = () => {
            const img = document.createElement('img')
            img.src = el.dataset.photo
            el.appendChild(img)
          }
          image.src = el.dataset.photo
        }
      })
    })
    observer.observe(el)
  })

  const ajaxopts = {
    headers: {
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
    },
  }
  const updateTooltip = (el, opts = {}) => {
    bootstrap.Tooltip.getInstance(el).dispose()
    return new bootstrap.Tooltip(el, opts)
  }
  const updateFavoriteBtn = (el, registered) => {
    if (registered) {
      updateTooltip(el, {title: 'お気に入り解除'})
      el.innerHTML = '<i class="fas fa-star"></i>'
    } else {
      updateTooltip(el, {title: 'お気に入り登録'})
      el.innerHTML = '<i class="far fa-star"></i>'
    }
  }

  let favorites = []
  $.ajax('/favorites.json', {type: 'get'}).done((res) => {
    favorites = res
    Array.from(document.querySelectorAll('.favorite-btn')).forEach((btn) => {
      const photoid = Number(btn.dataset.id)
      updateFavoriteBtn(btn, favorites.some((f) => f.photo_id == photoid))
    })
  })

  Array.from(document.querySelectorAll('.favorite-btn')).forEach((btn) => {
    btn.addEventListener('click', () => {
      const photoid = Number(btn.dataset.id)
      const favorite = favorites.filter((f) => f.photo_id == photoid)[0]
      if (favorite) {
        $.ajax(`/favorites/${favorite.id}.json`, {type: 'delete', ...ajaxopts}).done(() => {
          favorites = favorites.filter((f) => f.id != favorite.id)
          updateFavoriteBtn(btn, false)
        })
      } else {
        $.ajax('/favorites.json', {
          type: 'post',
          data: {
            favorite: {
              photo_id: photoid,
            }
          },
          ...ajaxopts,
        }).done((res) => {
          favorites.push(res)
          updateFavoriteBtn(btn, true)
        })
      }
    })
  })
})